<template>
  <div class="view-wrapper align-center overflow-y-auto">
    <v-card class="px-2 ma-2 mt-12 elevation-5" style="width: 350px;">
      <v-card-title class="justify-center">
        {{ $localize('password_recovery') }}
      </v-card-title>
      <v-card-text @keydown.enter="resetPassword">
        <v-text-field v-model="item.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.password]"
                      :type="showPassword ? 'text' : 'password'"
                      :label= "$localize('password')"
                      outlined
                      clearable
                      dense
                      @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field v-model="repeatedPassword"
                      :append-icon="showRepeatedPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.password]"
                      :type="showRepeatedPassword ? 'text' : 'password'"
                      :label= "$localize('repeat_password')"
                      outlined
                      clearable
                      dense
                      @click:append="showRepeatedPassword = !showRepeatedPassword"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0 mb-7">
        <v-row class="align-center"
               justify="space-around">
          <v-btn dark
                 color="success"
                 style="display: flex; flex: 0.7;"
                 :outlined="!isFormFilled"
                 @click="resetPassword"
          >
            {{ $localize('save_new_password') }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      item: {
        password: undefined,
        code: undefined,
      },
      repeatedPassword: undefined,
      showPassword: false,
      showRepeatedPassword: false,
      isSaveButtonDisabled: false,

      rules: {
        required: value => !!value || this.$localize('mandatory_field'),
        password: value => {
          return this.$testRegex('strongPassword', value) || this.$localize('password_should_be_not_less_than_8_symbols_and_contains_a_digit_and_letter_in_upper_and_lower_case')
        },
      },
    }
  },

  computed:{
    isFormFilled(){
      if (!this.item.password) return false;
      else if (this.isSaveButtonDisabled) return false;
      else if (!this.repeatedPassword) return false;
      else if (this.item.password && !this.$testRegex('strongPassword', this.item.password)) return false;
      else if (this.repeatedPassword && !this.$testRegex('strongPassword', this.repeatedPassword)) return false;
      else if (this.repeatedPassword !== this.item.password) return false;
      return true;
    }
  },

  mounted(){
    this.item.code = this.$route.params.code;
  },

  methods:{
    async resetPassword(){
      if(this.isFormFilled){
        this.isSaveButtonDisabled = true
        this.$appUtils.setOverlay(true)
        const res = await this.$fetch.post('api/auth/reset-password', this.item, true);
        this.$appUtils.setOverlay(false)
        if(res && res.status === 'ACCESS') {
          this.$router.push('/home').catch(() => {})
          this.$store.dispatch('setError',{ show: true, type: 'success', message: 'new_password_set'})
        }else{
          this.$store.dispatch('setError',{ show: true, type: 'error', message: 'error_try_again_later'})
          this.isSaveButtonDisabled = false
        }
      }else this.$store.dispatch('setError',{ show: true, type: 'warning', message: 'fill_all_fields_correctly'})
    }
  }
}
</script>
